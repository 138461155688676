import React from 'react'
import { graphql, Link } from 'gatsby';
import Image from "gatsby-image";

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Hero from 'components/Hero';

import greenCheckmark from 'img/green-checkmark.svg';
import "styles/AboutTemplate.scss";

const HeroContent = (hero) => (
  <>
    <div className="hero-column__checkmarks">
      {hero.checkmarks.map((checkmark, key) => (
        <div key={key} className="d-flex align-items-center mb-3">
          <img src={greenCheckmark} alt="Checkmark" className="mr-3" />
          <strong>{checkmark.tekst}</strong>
        </div>
      ))}
    </div>

    <Content className="mb-4" content={hero.description} />
  </>
)

const About = ({ data }) => {
  const { wordpressPage: page } = data
  const { acf: { hero, background, connected, committed, craftsmen, cta, team } } = page;

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} lang={page.wpml_current_locale.slice(0, 2)} />
      <div className="about-page">
        <Hero
          className="about-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          title={hero.title}
          description={HeroContent(hero)}
        />

        <section className="background container">
          <div className="background__inner">
            {background.map(({ text }) => (
              <h1>
                {text}
              </h1>
            ))}
          </div>
        </section>

        <section className="connected core">
          <div className="container">
            <div className="row">
              <Content className="core-content col-lg-6" content={connected.content} />
              <div className="col-lg-6 pl-0 pr-0">
                <div className="core-image__wrapper">
                  <Image
                    fixed={connected.image.localFile.childImageSharp.fixed}
                    className="core-image"
                  />
                  <div className="core-image__content">
                    <h2>{connected.title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="committed core">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 pl-0 pr-0 order-last order-lg-first order-xl-first">
                <div className="core-image__wrapper">
                  <Image
                    fixed={committed.image.localFile.childImageSharp.fixed}
                    className="core-image"
                  />
                  <div className="core-image__content">
                    <h2>{committed.title}</h2>
                  </div>
                </div>
              </div>
              <Content className="core-content col-lg-6 order-first order-lg-last order-xl-last" content={committed.content} />
            </div>
          </div>
        </section>

        <section className="craftsmen core">
          <div className="container">
            <div className="row">
              <Content className="core-content col-lg-6" content={craftsmen.content} />
              <div className="col-lg-6 pl-0 pr-0">
                <div className="core-image__wrapper">
                  <Image
                    fixed={craftsmen.image.localFile.childImageSharp.fixed}
                    className="core-image"
                  />
                  <div className="core-image__content">
                    <h2>{craftsmen.title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta">
          <div className="container">
            <Content content={cta.text} />
            <div className="d-flex mt-4 justify-content-center">
              <Link className="button" to={cta.link.relations.path}>
                {cta.link.label}
              </Link>
            </div>
          </div>
        </section>

        <section className="team">
          <div className="container">
            <h2 className="with-dot mb-5">{team.title}</h2>
            <div className="row">
              {team.relations.map((member) => (
                <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="team-member">
                    <Image
                      fluid={member.acf.image.localFile.childImageSharp.fluid}
                      className="team-member__image"
                    />
                    <p className="text-center mb-0 mt-2"><strong>{member.title}</strong></p>
                    <p className="text-center">{member.acf.functie}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutPageByID($wordpress_id: Int!) {
    wordpressPage(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          title
          checkmarks {
            tekst
          }
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        background {
          text
        }

        connected {
          title
          content
          image {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 720, height: 504) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }

        committed {
          title
          content
          image {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 720, height: 504) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }

        craftsmen {
          title
          content
          image {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 720, height: 504) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }

        cta {
          text
          link {
            label
            relations {
              path
            }
          }
        }

        team {
          title
          relations {
            title
            path
            acf {
              functie
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 350) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
